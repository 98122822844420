<template>
  <section class="l-content">
    <el-card class="l-condition" name="hTable">
      <el-form :inline="true" :model="condition">
        <el-form-item label="使用状态">
          <el-radio-group v-model="condition.state" size="small" @change="getPageData()">
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button label="0">未使用</el-radio-button>
            <el-radio-button label="1">使用中</el-radio-button>
            <el-radio-button label="9">已停用</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-input v-model="condition.deviceNo" size="small" placeholder="设备编号"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getPageData()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider> </el-divider>
    <el-card v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex">
        <template slot="CCID" slot-scope="scope">
          <el-button type="text" @click="viewCCID(scope.row)">{{ scope.row.ccid }}</el-button>
        </template>
        <template slot="state" slot-scope="scope">
          <span class="text-green bold" v-if="scope.row.state == 1">使用中</span>
          <span class="text-gray bold" v-else-if="scope.row.state == 0">未使用</span>
          <span class="text-red bold" v-else-if="scope.row.state == 9">已停用</span>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData"></l-pager>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import VSimWXView from './sim/simWXView'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'CarList',
  data() {
    return {
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: 'CCID', prop: 'ccid' },
          { label: '设备编号', prop: 'deviceNo' },
          { label: '有效期开始', prop: 'startDate' },
          { label: '有效期结束', prop: 'endDate' },
          { label: '使用状态', slot: 'state' },
          { label: '备注', prop: 'remark', overflow: true }
        ],
        operation: {
          width: '150',
          buttons: [{ type: 'primary', label: '流量查询', click: this.viewCCID }]
        }
      },
      condition: {
        deviceNo: '',
        state: '1',
        startDate: this.$lgh.getDate(),
        endDate: this.$lgh.getDate(),
        pageIndex: 1,
        pageSize: 20
      },
      pageData: null
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    dataRangeChange(d1, d2) {
      this.condition.startDate = d1
      this.condition.endDate = d2
    },
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/platform/gps/sim/list', [this.condition])
    },
    viewCCID(item) {
      var url = ''
      if (item.source === 'A') {
        url = 'http://wx.szgzwlw.com/find?version=1'
      } else if (item.source === 'B') {
        url = 'http://piccwechat.18gps.net/SIM/SimQuery.aspx?openid=oK_iVs9zgCwF5CIG-fsenlv6GMqI&appid=wx76caa5acdb89d158&macid='
      }
      if (url) {
        window.open(url)
      }
    }
    // async viewCCID(item) {
    //   await this.$lgh.openDialog({
    //     ins: VSimWXView,
    //     parent: this,
    //     title: '流量查询',
    //     data: {
    //       dataInfo: item
    //     }
    //   })
    // }
  }
}
</script>
