<template>
  <el-drawer :title="title" :visible.sync="visible" :destroy-on-close="true">
    <div class="flex flex-direction" style="height:100%;">
      <el-card class="flex-sub overflow" v-if="iframeUrl">
        <iframe :url="iframeUrl" style="border:0;margin:0;padding:0;height:100%;width:100%;"></iframe>
      </el-card>
    </div>
  </el-drawer>
</template>

<script>
export default {
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      iframeUrl: null
    }
  },
  mounted() {
    this.iframeUrl = `http://wx.szgzwlw.com/message?sim=${this.dataInfo.ccid}&version=1`
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    }
  }
}
</script>

<style lang="less">
.detail_area {
  height: 100%;
  textarea {
    height: 100%;
    background-color: rgb(30, 30, 30);
    color: rgb(220, 220, 220);
  }
}
</style>
>
